import PropTypes from 'prop-types';

ModalOverlay.propTypes = {
    children: PropTypes.node.isRequired,
};

export default function ModalOverlay({
    children,
}) {
    return <div className="modal-overlay">
        {children}
    </div>;
}
